<template>
  <main class="home-page">
    <ul class="page-list">
      <li v-for="bracelet in bracelets" class="list-item" :key="bracelet.id">
        <figure class="item-image">
          <img :src="bracelet.imagePath" alt="Bracelet Image">
        </figure>

        <section class="item-content">
          Name: {{ bracelet.displayName }}
          Price: $5.00
        </section>
      </li>
    </ul>
  </main>
</template>

<script>
import { bracelets } from '@siteData/bracelets';
export default {
  name: 'HomeView',
  components: {},
  data () {
    return {
      bracelets
    }
  }
}
</script>
<style lang="scss">
  .home-page {

    .page-list {

      .list-item {
        border: 2px solid gainsboro;
        margin: 0 auto 1em;
        width: 15em;
        
        .item-image {

        }
        
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>