<template>
  <NavigationMain />

  <router-view/>
</template>
<script>
import NavigationMain from '@components/NavigationMain.vue';
export default {
  components: {
    NavigationMain
  }
}
</script>
<style lang="scss">
</style>
