<template>
<nav class="main-navigation">
  <figure class="navigation-logo">
    <img src="images/logo.png" alt="caileymoreland.com logo">
  </figure>

  <ul class="navigation-list">
    <li v-for="link in navlinks" class="list-item" :key="link.id">
      <router-link :to="link.route" class="item-link">
        {{ link.displayName }}
      </router-link>
    </li>
  </ul>
</nav>
</template>
<script>
import { navlinks } from '@siteData/navigation';

export default {
  data () {
    return {
      navlinks
    }
  }
}
</script>
<style lang="scss">
.main-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5em 1em;

  .navigation-logo {
    width: 10em;

    img {
      width: 100%;
    }
  }

  .navigation-list {
    display: flex;
    align-items: center;

    .list-item {
      display: inline-block;
      margin: 0.5em;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        padding: 0.5em 1em;
      }
    }
  }
}
</style>